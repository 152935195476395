import store from "@/store";
import { theme } from "@/views/theme";
import tools from "@/utils/tools";
import { bus } from "@/components/core/bus";
import router from "@/router/index";
import { getApiLoginUrl } from "@/components/protocol/api";
import action from "@/components/core/action";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import log from "./logger";
let comApi = {
  setUserInfo() {
    let userInfo = store.getters["user/userInfo"];
    mqant.request(topic.userInfo, {}, function (res, topic, msg) {
      if (res.Code == 0) {
        userInfo = res.Data;
      }
    });
  },

  getNormalImg(url) {
    let lobby = store.getters["global/lobbyInfo"];
    return lobby.CdnDomain + url;
  },

  getLogoImg() {
    let lobby = store.getters["global/lobbyInfo"];
    return lobby.CdnDomain + lobby.CustomerInfo.pcLogo;
    if (theme == "pc") {
      return lobby.CdnDomain + lobby.CustomerInfo.pcLogo;
    } else {
      return lobby.CdnDomain + lobby.CustomerInfo.appLogo;
    }
  },

  getFactoryIcon(name) {
    let lobby = store.getters["global/lobbyInfo"];
    let curNameObj = lobby.FactoryList.find((info) => info.FactoryName == name);
    if (!curNameObj) return "";
    return this.getNormalImg(curNameObj.Icon);
  },

  getFactoryGameIcon(item, type = 2) {
    if (!item) return;
    if (item.PType == "factory") {
      return this.getNormalImg(item.PUrl);
    }
    //type:2正方型
    if (item.Mthumbnail) {
      if (item.Mthumbnail.indexOf("http") !== 0) {
        return this.getNormalImg(item.Mthumbnail);
      }
    }
    let lobby = store.getters["global/lobbyInfo"];
    let lang = tools.getLanguage();
    lang = "en";
    lang = "en";
    // let str =
    //   lobby.HotDomain +
    //   "/gameIcon2" +
    //   "/" +
    //   item.FactoryName +
    //   "/" +
    //   lang +
    //   "/" +
    //   item.GameCode +
    //   ".png";
    let str =
      lobby.HotDomain +
      "/gameIcon" +
      type +
      "/" +
      item.FactoryName +
      "/" +
      lang +
      "/" +
      item.GameCode +
      ".png";
    return str;
  },

  getFactoryShowName(name) {
    switch (name) {
      case "apiCq":
        name = "apiCq9";
        break;
      case "apiAwc":
        name = "apiAe";
        break;
    }
    let temp = name.slice(3).toUpperCase();
    return temp;
  },

  getRouterConf() {
    // id对应的路由 Map
    const pcMap = new Map([
      ["Slot", "/slot"],
      ["Fish", "/fish"],
      ["CockFight", "/cockFight"],
      ["Card", "/chess"],
      ["LiveSports", "/sport"],
      ["LiveCasino", "/casino"],
      ["Lottery", "/lottery"],
      ["Special", "/special"],
      ["Custom", "/otherGame"],
      ["VIP", "/vip"],
      ["Activity", "/promotion"],
    ]);

    const mobileMap = new Map([
      ["Slot", "/slot"],
      ["Fish", "/fish"],
      ["CockFight", "/cockFight"],
      ["Card", "/chess"],
      ["LiveSports", "/sport"],
      ["LiveCasino", "/casino"],
      ["Lottery", "/lottery"],
      ["Special", "/special"],
      ["Custom", "/otherGame"],
      ["VIP", "/vip"],
      ["Activity", "/promotion"],
    ]);
    return [pcMap, mobileMap];
  },

  isRouterInNav(router) {
    let [pcMap, mobileMap] = this.getRouterConf();
    let map = theme == "pc" ? pcMap : mobileMap;
    let hasInMap = false;
    map.forEach((value, key) => {
      if (value == router) {
        hasInMap = true;
      }
    });
    return hasInMap;
  },

  getRouterList() {
    let [pcMap, mobileMap] = this.getRouterConf();
    let map = theme == "pc" ? pcMap : mobileMap;
    let lobby = store.getters["global/lobbyInfo"];
    let arr = tools.deepClone(lobby.MainPageConf);
    for (let key in arr) {
      let info = arr[key];
      map.forEach((value, key) => {
        if (key == info._id && value != "") {
          info.router = value;
        }
      });
      //排序一下
      if (info.SubFactory && Object.keys(info.SubFactory).length > 0) {
        info.SubFactory = tools.sortObject(info.SubFactory, [
          "factoryInfo",
          "Sort",
        ]);
      }
    }
    return arr;
  },

  getShowNameById(id) {
    const idMap = new Map([
      ["Slot", "Slot"],
      ["Fish", "Fish"],
      ["CockFight", "CockFight"],
      ["Card", "Chess"],
      ["LiveSports", "Sport"],
      ["LiveCasino", "Casino"],
      ["Lottery", "Lottery"],
      ["Special", "Special"],
      ["Custom", "OtherGame"],
      ["VIP", "Vip"],
      ["Activity", "Promotion"],
    ]);
    return idMap.get(id) || "";
  },

  getHorseLampList() {
    let arr = [];
    let lobby = store.getters["global/lobbyInfo"];
    for (let key in lobby.MainCustomContent) {
      let info = lobby.MainCustomContent[key];
      if (info.type == "runText" && info.status) {
        arr.push(info);
      }
    }
    return arr;
  },

  eventJumpByType(item) {
    if (!item) return;
    //clickType:1:无动作，2:内部链接，3:开启游戏，4:开启活动，5:外部链接
    let isLogged = store.getters["tcp/isLogged"];
    switch (item.clickType) {
      case 1:
        break;
      case 2:
        let index = item.selfLink.indexOf("#");
        if (index <= 0) {
          index = item.selfLink.indexOf("/");
        }
        if (index <= 0) return;
        let routerStr = item.selfLink.slice(index + 1);
        //需要登录验证的路由得处理
        if (routerStr.includes("member")) {
          if (!isLogged) {
            bus.emit(bus.event.loginState, true);
            return;
          }
        }
        router.push({
          path: routerStr,
        });
        break;
      case 3:
        if (!isLogged) {
          bus.emit(bus.event.loginState, true);
          return;
        }
        let url = getApiLoginUrl(item.factoryName, item.gameCode);
        action.openPage(url);
        break;
      case 4:
        router.push({
          name: "promotion",
          params: {
            id: item.activityId,
          },
        });
        break;
      case 5:
        window.open(item.outLink);
        break;
    }
  },
  eventJumpByNotice(item) {
    if (!item) return;
    //type:1:游戏，2:开启活动，3事件（1，无动作，2内部链接，3外部链接）
    let isLogged = store.getters["tcp/isLogged"];
    let type = Number(item.type);
    switch (type) {
      case 1:
        if (!isLogged) {
          bus.emit(bus.event.loginState, true);
          return;
        }
        let url = getApiLoginUrl(item.factoryName, item.gameCode);
        action.openPage(url);
        break;
      case 2:
        router.push({
          name: "promotion",
          params: {
            id: item.activityId,
          },
        });
      case 3:
        if (item.clickType == 1) {
        } else if (item.clickType == 2) {
          let index = item.selfLink.indexOf("#");
          if (index <= 0) {
            index = item.selfLink.indexOf("/");
          }
          if (index <= 0) return;
          let routerStr = item.selfLink.slice(index + 1);
          //需要登录验证的路由得处理
          if (routerStr.includes("member")) {
            if (!isLogged) {
              bus.emit(bus.event.loginState, true);
              return;
            }
          }
          router.push({
            path: routerStr,
          });
        } else if (item.clickType == 3) {
          window.open(item.outLink);
        }
        break;
    }
  },
  toLoginOrRegister(index = 0) {
    if (index == 0) {
      bus.emit(bus.event.loginState, true);
    } else {
      bus.emit(bus.event.resgisterPop, true);
    }
  },
  toFactory(item) {
    let isLogged = store.getters["tcp/isLogged"];
    if (!isLogged) {
      bus.emit(bus.event.loginState, true);
      return;
    }
    let factoryName = "";
    let gameCode = "";
    if (item.FactoryName) {
      factoryName = item.FactoryName;
    } else {
      if (item.Factory) {
        factoryName = item.Factory;
      }
    }
    if (item.GameCode) {
      gameCode = item.GameCode;
    } else {
      if (item.gameCode) {
        gameCode = item.gameCode;
      }
    }
    if (item.GameClass) {
      //判断游戏所在的厂商是否维护了
      //老虎机，棋牌，捕鱼
      let isMaintainState = false;
      let lobby = store.getters["global/lobbyInfo"];
      let curObj = lobby.MainPageConf.find(
        (info) => info._id == "Slot"
      ).SubFactory;
      for (let key in curObj) {
        if (item.FactoryName == key) {
          if (curObj[key].factoryInfo && curObj[key].factoryInfo.Status == 2) {
            isMaintainState = true;
            break;
          }
        }
      }
      if (isMaintainState) {
        //厂商维护，
        return;
      } else {
        if (item.Status == 3) {
          //Status == 3 游戏维护，
          return;
        }
      }
    } else {
      if (item.Status == 2) {
        //Status == 2 厂商维护
        return;
      }
    }
    let url = getApiLoginUrl(factoryName, gameCode);
    log.info("openPage-url--", url);
    action.openPage(url);
  },

  getFactoryImg(item, type_index = 0) {
    if (!item) return;
    // let showImgArr = theme == "pc" ? item.ShowImg : item.ShowImgMobile;
    let showImgArr = item.ShowImg;
    if (showImgArr && showImgArr.length > 0 && showImgArr[type_index]) {
      return this.getNormalImg(showImgArr[type_index]);
    }
    return "";
  },

  getSlotNavList() {
    let categoryObj = {
      Title: "Category",
      Icon: require("@/assets/pc/home/icon-type.png"),
      list: [],
      localeFlag: true, //表示本地写死数据
    };
    let getLabelList = function (list) {
      let arr = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].Status == 1) {
          arr.push(list[i]);
        }
      }
      return arr;
    };
    let routerAllList = this.getRouterList();
    let routerList = [];
    let slotData = routerAllList.find((info) => info._id == "Slot");
    let slotLabelList = [];
    if (slotData) {
      slotLabelList = getLabelList(slotData.Labels);
      if (slotLabelList.length > 0) {
        categoryObj.list = slotLabelList;
        routerList.push(categoryObj);
      }
    }
    //排外一些特殊数据，根据不同的盘做处理
    for (let i = 0; i < routerAllList.length; i++) {
      let info = routerAllList[i];
      if (
        info._id !== "CockFight" &&
        info._id !== "Custom" &&
        info._id !== "Activity" &&
        info._id !== "VIP"
      ) {
        routerList.push(info);
      }
    }
    // log.info("routerList--", routerList);
    return routerList;
  },

  getCurNavListByType(type) {
    let arr = [];
    let lobby = store.getters["global/lobbyInfo"];
    let curObj = lobby.MainPageConf.find(
      (info) => info._id == type
    )?.SubFactory;
    if (!curObj) return arr;
    for (let key in curObj) {
      arr.push(curObj[key]);
    }
    arr.sort((a, b) => {
      return a.factoryInfo.Sort - b.factoryInfo.Sort;
    });
    log.info("getCurNavListByType,type", arr, type);
    return arr;
  },
};

export default comApi;
