<template>
  <div class="home">
    <Sidebar />
    <div class="banner">
      <div class="banner-wrapper">
        <swiper :modules="bannerObj.modules" :autoplay="{ delay: bannerObj.delay, disableOnInteraction: false }" :loop="true" :circular="true" :grab-cursor="true" :pagination="{ clickable: true }" slides-per-view="auto">
          <swiper-slide v-for="(item, index) in bannerObj.list" :key="item">
            <a>
              <div class="platform-menu-img">
                <img :src="comApi.getNormalImg(tools.getCurLangValue(item.icon))" alt="">
              </div>
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div v-if="!isLogged" class="m-login-join-btn-container">
      <div class="btn-reg" @click="toRegister()">
        <p class="btn-text">{{t('注册')}}</p>
      </div>
      <div class="btn-login" @click="toLoginPop()">
        <p class="btn-text">{{t('登录')}}</p>
      </div>
    </div>
    <div class="mobileGameTabContainer">
      <div>
        <div class="mobile-menu-tab">
          <div class="mobile-menu-tab-box">
            <div v-for="(item,index) in navObj.list" class="menu-item" :class="index == navObj.index ? 'menu-selected':''" @click="navItemClick(item,index)">
              <img v-if="index == navObj.index" class="mobile-menu-tab-svg" :src="comApi.getNormalImg(item.Icon)" alt="">
              <p class="standard-SmallsubContent-font-size">{{tools.getCurLangValue(item.Title)}}</p>
            </div>
          </div>
        </div>
        <div class="submenu-item-container">
          <HomeSlot v-show="navObj.list[navObj.index]._id == 'Slot'" />
          <HomeCasino v-show="navObj.list[navObj.index]._id == 'LiveCasino'" />
          <HomeSport v-show="navObj.list[navObj.index]._id == 'LiveSports'" />
          <HomeLottery v-show="navObj.list[navObj.index]._id == 'Lottery'" />
          <HomeChess v-show="navObj.list[navObj.index]._id == 'Card'" />
          <HomeFish v-show="navObj.list[navObj.index]._id == 'Fish'" />
          <HomeSpecial v-show="navObj.list[navObj.index]._id == 'Special'" />
        </div>
      </div>
    </div>
    <div class="games-section recom1-game-section">
      <div class="top-container">
        <p class="games-title">{{tools.getCurLangValue(recom1Obj.data.Text)}}</p>
        <p class="see-all-btn" @click="toRouter('/slot')">{{t('查看全部')}}</p>
      </div>
      <div class="games-slider">
        <div class="game-content-wrapper">
          <div v-for="(item, index) in recom1Obj.list" :key="item" @click="comApi.toFactory(item.GameDetail ? item.GameDetail:item)" class="game-container">
            <div class="game-img-warpper">
              <img :src="comApi.getFactoryGameIcon(item.GameDetail ? item.GameDetail:item,3)" alt="">
            </div>
            <div class="game-content">
              <p class="game-name">{{ item.GameDetail ? item.GameDetail.GameNameEn :comApi.getFactoryShowName( item.Factory )}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="games-section recom2-game-section">
      <div class="top-container">
        <p class="games-title">{{tools.getCurLangValue(recom2Obj.data.Text)}}</p>
        <p class="see-all-btn" @click="toRouter('/casino')">{{t('查看全部')}}</p>
      </div>
      <div class="games-slider">
        <div class="game-content-wrapper">
          <div v-for="(item, index) in recom2Obj.list" :key="item" @click="comApi.toFactory(item.GameDetail ? item.GameDetail:item)" class="game-container">
            <div class="game-img-warpper">
              <img :src="comApi.getFactoryGameIcon(item.GameDetail ? item.GameDetail:item)" alt="">
            </div>
            <div class="game-content">
              <p class="game-name">{{ item.GameDetail ? item.GameDetail.GameNameEn :comApi.getFactoryShowName( item.Factory )}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="games-section recom3-game-section">
      <div class="top-container">
        <p class="games-title">{{tools.getCurLangValue(recom3Obj.data.Text)}}</p>
        <p class="see-all-btn" @click="toRouter('/slot')">{{t('查看全部')}}</p>
      </div>
      <div class="games-slider">
        <div class="game-content-wrapper">
          <div v-for="(item, index) in recom3Obj.list" :key="item" @click="comApi.toFactory(item.GameDetail ? item.GameDetail:item)" class="game-container">
            <div class="game-img-warpper">
              <img :src="comApi.getFactoryGameIcon(item.GameDetail ? item.GameDetail:item,3)" alt="">
            </div>
            <div class="game-content">
              <p class="game-name">{{ item.GameDetail ? item.GameDetail.GameNameEn :comApi.getFactoryShowName( item.Factory )}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="app-landing-section mobile-app-landing-section">
      <div class="app-landing-content">
        <div class="header-container">
          <div class="header-1-title">
            <p>{{t('游戏的未来就在这里')}}</p>
            <p>{{t('任何设备都能运行的好游戏')}}</p>
            <p>{{t('全新应用')}}</p>
          </div>
          <div class="header-2">{{t('现在注册以获得无限奖励、优惠和奖品.')}}</div>
        </div>
        <div class="app-download-container">
          <div class="download-item">
            <div class="os-name">
              <img src="@/assets/pc/home/svg-android.svg" alt="">
              {{t('安卓')}}
            </div>
            <div class="instruction-btn">{{t('操作说明')}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import Sidebar from "@/views/mobile/components/Sidebar.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, FreeMode, Navigation } from "swiper";
import "swiper/swiper.css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import { useI18n } from "vue-i18n";

import HomeSlot from "@/views/mobile/components/home/HomeSlot.vue";
import HomeSport from "@/views/mobile/components/home/HomeSport.vue";
import HomeCasino from "@/views/mobile/components/home/HomeCasino.vue";
import HomeFish from "@/views/mobile/components/home/HomeFish.vue";
import HomeChess from "@/views/mobile/components/home/HomeChess.vue";
import HomeLottery from "@/views/mobile/components/home/HomeLottery.vue";
import HomeSpecial from "@/views/mobile/components/home/HomeSpecial.vue";


const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

//轮播图
let bannerObj = reactive({
  list: getBannerList(),
  modules: [Autoplay, Pagination],
  delay: 3000,
})
//导航栏
let navObj = reactive({
  index: 0,
  list: getNavList(),
})


//推荐1
let [recom1Data, recom1AllList] = getDataListByType('HotLeft');
const recom1Obj = reactive({
  total: recom1AllList.length,
  data: recom1Data,
  list: getLimitList(recom1AllList, 5),
  modules: [Pagination, Navigation],
  delay: 5000,
  navigation: {
    nextEl: ".swiper-button-next-recom1",
    prevEl: ".swiper-button-prev-recom1",
  },
})

//推荐2
let [recom2Data, recom2AllList] = getDataListByType('HotRight');
const recom2Obj = reactive({
  total: recom2AllList.length,
  data: recom2Data,
  list: getLimitList(recom2AllList, 5),
  modules: [Pagination, Navigation],
  delay: 5000,
  navigation: {
    nextEl: ".swiper-button-next-recom2",
    prevEl: ".swiper-button-prev-recom2",
  },
})

//推荐3
let [recom3Data, recom3AllList] = getDataListByType('HotRecommend');
const recom3Obj = reactive({
  total: recom3AllList.length,
  data: recom3Data,
  list: getLimitList(recom3AllList, 5),
  modules: [Pagination, Navigation],
  delay: 5000,
  navigation: {
    nextEl: ".swiper-button-next-recom3",
    prevEl: ".swiper-button-prev-recom3",
  },
})
//厂商列表
let factoryList = ref(getFactoryList());


function getNavList() {
  let arr = [];
  for (let key in lobbyInfo.value.MainPageConf) {
    let info = lobbyInfo.value.MainPageConf[key]
    if (info._id != 'Activity' && info._id != 'VIP') {
      arr.push(info);
    }
  }
  log.info('getNavList---', arr)
  return arr;
}

function navItemClick(item, index) {
  if (index == navObj.index) return;
  navObj.index = index
}

function getBannerList() {
  let arr = [];
  for (let key in lobbyInfo.value.MainCustomContent) {
    let info = lobbyInfo.value.MainCustomContent[key]
    if (info.type == 'banner') {
      let nowTime = Date.now();
      let startTime = Date.parse(info.time[0]);
      let endTime = Date.parse(info.time[1]);
      if (nowTime > startTime && nowTime < endTime) {
        if (tools.isMobile()) {
          if (info.platformType !== 'pc' && info.status) {
            arr.push(info);
          }
        } else {
          if (info.platformType == 'pc' && info.status) {
            arr.push(info);
          }
        }
      }
    }
  }
  arr.sort((a, b) => {
    return b.sort - a.sort;
  })
  log.info('bannerList---', arr);
  return arr;
}


function getDataListByType(navigate) {
  let allRecommend = lobbyInfo.value.AllRecommend;
  let curObj = allRecommend.labels.find(info => info.Navigate == navigate);
  if (!curObj) return [{}, []];
  let arr = [];
  for (let key in allRecommend.all) {
    if (navigate == key) {
      arr = allRecommend.all[key];
      break;
    }
  }
  let newArr = [];
  for (let key in arr) {
    if (arr[key].GameDetail) {
      if (arr[key].GameDetail.Status != 4) {
        newArr.push(arr[key])
      }
    } else {
      if (arr[key].Status != -1) {
        newArr.push(arr[key])
      }
    }
  }
  return [curObj, newArr];
}

function getLimitList(list, num) {
  // return list.slice(0, num);
  return list
}

function getFactoryList() {
  let arr = [];
  for (let key in lobbyInfo.value.FactoryList) {
    if (lobbyInfo.value.FactoryList[key].Status == 1) {
      arr.push(lobbyInfo.value.FactoryList[key])
    }
  }
  return arr;
}

function getMaintainState(item) {
  if (item.GameDetail) {
    //是子游戏
    if (item.Status == 2) {
      return true
    }
    if (item.GameDetail.Status == 3) {
      return true
    }
  } else {
    if (item.Status == 2) {
      return true
    }
  }
  return false;
}

function toRouter(path) {
  router.push({
    path: path
  }
  )
}
function toRegister() {
  bus.emit(bus.event.resgisterPop, true);
}

function toLoginPop() {
  bus.emit(bus.event.loginState, true);
}

</script>

<style lang="scss" scoped>
.home {
  margin-top: 0 !important;
  .banner {
    overflow: hidden;
    .banner-wrapper {
      overflow: visible;
      :deep(.swiper) {
        overflow: visible;
        .swiper-slide:first-child {
          margin-left: 0;
          padding-left: 0;
        }

        .swiper-slide {
          width: 100%;
          position: relative;
          a {
            position: relative;
            color: #fff;
            font-size: 1rem;
            display: block;
            width: 100%;
            line-height: 30px;
            .platform-menu-img {
              position: relative;
              width: 100%;
              height: 0;
              padding-bottom: 30%;
              img {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }

        .active,
        .swiper-slide:hover {
          a {
            opacity: 1;
            background: hsla(0, 0%, 100%, 0.2);
            color: #fff;
            border-radius: 5px;
          }
        }
        .swiper-pagination {
          bottom: -10px;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          .swiper-pagination-bullet {
            margin: 0 4px;
            display: inline-block;
            padding: 0;
            position: relative;
            cursor: pointer;
            width: 35px;
            height: 5px;
            background: rgba(233, 132, 126, 0.5);
            border-radius: 10px;
            color: transparent;
            opacity: 0.5;
          }

          .swiper-pagination-bullet-active {
            background: #e9847e;
            opacity: 1;
          }
        }
      }
    }
  }
  .m-login-join-btn-container {
    display: flex;
    height: 45px;
    > div {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      border-radius: 0;
      cursor: pointer;
    }
    .btn-reg {
      background: #cb011b;
    }
    .btn-login {
      background: #0071e3;
    }
  }
  .mobileGameTabContainer {
    .mobile-menu-tab {
      display: flex;
      align-items: center;
      width: 95%;
      background: #f3f5f6;
      border-radius: 25px;
      overflow: hidden;
      margin: 0 10px;
      .mobile-menu-tab-box {
        display: flex;
        align-items: center;
        overflow-x: scroll;
        padding: 5px 8px;
        .menu-item {
          position: relative;
          display: flex;
          align-items: center;
          min-width: fit-content;
          height: 100%;
          border-radius: 25px;
          font-size: 16px;
          color: #8697a2;
          margin: 0 13px;
          cursor: pointer;
          white-space: nowrap;
          padding: 7px 0 5px;
          img {
            height: 20px;
            width: 20px;
            opacity: 0;
            transition: opacity 0.2s ease-in;
          }
          p {
            font-size: 12px;
            text-transform: capitalize;
          }
        }
        .menu-item:first-child {
          margin: 0 13px 0 0;
        }
        .menu-selected {
          background: linear-gradient(to right, #095ebd, #04a9c2);
          color: #fff;
          margin: 2px;
          padding: 4px 7px 4px 5px;
          img {
            opacity: 1;
          }
          p {
            margin-left: 4px;
            font-size: 14px;
          }
        }
        .menu-selected:first-child {
          margin: 0 2px 0 0;
        }
      }
      .mobile-menu-tab-box::-webkit-scrollbar {
        display: none;
      }
    }
    .submenu-item-container {
      margin: 7px 10px 0;
    }
  }
  .games-section {
    padding: 0 0 0 10px;
    margin: 30px 0 0;
    .top-container {
      padding: 0 10px 0 10px;
      margin: 0 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #0f161f;
      margin: 0 0 11px;
      font-size: 14px;
      .games-title {
        font-weight: 600;
      }
      .see-all-btn {
        text-decoration: underline;
        cursor: pointer;
        font-size: 13px;
      }
    }
    .games-slider {
      font-weight: 600;
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      .game-content-wrapper {
        white-space: nowrap;
        width: fit-content;
        display: flex;
        .game-container {
          width: 120px;
          margin: 0 8px 0 0;
          .game-img-warpper {
            width: 100%;
            padding-bottom: 60%;
            background: rgba(0, 0, 0, 0.1019607843);
            overflow: hidden;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            img {
              position: absolute;
              left: 0;
              right: 0;
              width: 100%;
              height: 100%;
              cursor: pointer;
              object-fit: cover;
              transition: opacity 0.2s ease-in;
            }
          }
          .game-content {
            margin: 8px 0 0;
            .game-name {
              font-size: 12px;
              margin: 0 0 0 7px;
              width: 100px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .games-slider::-webkit-scrollbar {
      display: none;
    }
  }
  .recom1-game-section,
  .recom3-game-section {
    .game-img-warpper {
      padding-bottom: 145% !important;
    }
  }

  .app-landing-section {
    margin-top: 30px;
    background-image: linear-gradient(to top right, #d1ea76 8%, #b5e6aa 100%);
    .app-landing-content {
      flex-direction: column;
      padding: 20px;
      display: flex;
      word-break: break-word;
      .header-container {
        width: 70%;
        .header-1-title {
          font-weight: 600;
          font-size: 18px;
          color: #0f161f;
        }
        .header-2 {
          margin: 13px 0 0;
          font-size: 12px;
        }
      }
      .app-download-container {
        margin: 25px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .download-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .os-name {
            margin: 0;
            width: 140px;
            border-radius: 5px;
            padding: 4px;
            font-size: 14px;
            background: #f3f6f6;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #0f161f;
            img {
              width: 16px;
              margin: 0 10px 0 0;
            }
          }
          .instruction-btn {
            margin: 10px 0 0;
            font-size: 15px;
            color: #06c;
          }
        }
      }
    }
  }
}
</style>